
export const objectToCSS = (object) => {

    let returnStr = ''

    Object.keys(object).forEach((key) => {
        returnStr += String(key) + ': ' + String(object[key]) + ';'
    })

    return returnStr

}