
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { objectToCSS } from 'modules/styled/objectToCSS'
import { breakpointMixin } from 'modules/styled/breakpoints'

const stringToSize = (props) => {
    switch(props.size) {
        case 'lg':      return '100vh'
        case 'md':      return '75vh'
        case 'sm':      return '50vh'
        case undefined: return ''
        default:        return String(props.size)
    }
}

const getMinHeight = (props) => {

    if (props.size && props.theme.navHeight) { 
        return `min-height: calc(${stringToSize(props)} - ${props.theme.navHeight});`
    
    } else if (props.size) {
        return `min-height: ${stringToSize(props)};`
    
    } else {
        return ''
    }
}

const getBackground = (props) => {
    let bgParams = []

    if (props.background) {

        let image = props.background.image
        let color = props.background.color

        if (image) {
            if (typeof(image) === 'string') {
                bgParams.push(`url(${image})`)
            
            } else if (image) {
                if (image.overlayColor) {
                    bgParams.push(`linear-gradient(${image.overlayColor}, ${image.overlayColor})`)
                }
    
                if (image.url) {
                    bgParams.push(`url(${image.url})`)
                }
    
            }
    
            if (props.background.color) {
                bgParams.push(props.background.color)
            }
    
            return bgParams.join(',')

        } else if (color) {
            return color

        }

    }

    return ''
}

const getBackgroundType = (props) => {
    switch(props.format) {
        case 'header':
        case 'thumbnail':
            return `
                background-attachment: fixed;
                background-position: center;
                background-size: cover;
            `
        case 'preview':
            return `
                background-position: center;
                background-size: contain;
            `
        default:
            return ''
    }

}

const alignToCSS = (align) => {
    switch(align) {
        case 'top':
        case 'left':
            return 'flex-start'
        case 'bottom':
        case 'right':
            return 'flex-end'
        case 'middle':
        case 'center':
            return 'center'
        default:
            return ''
    }
}

export const Section = styled.div`
    ${breakpointMixin}
    ${getMinHeight}
    
    background:         ${props => getBackground(props)};
    ${getBackgroundType}

    position:           relative;
    display:            flex;
    flex-direction:     ${props => props.direction};
    flex-wrap:          ${props => props.wrap};
    justify-content:    ${props => props.direction === 'row'
                            ? alignToCSS(props.align.horizontal)
                            : alignToCSS(props.align.vertical)};
    align-items:        ${props => props.direction === 'row' 
                            ? alignToCSS(props.align.vertical) 
                            : alignToCSS(props.align.horizontal)};

    ${props => { if(props.css) { return objectToCSS(props.css)} } }
    ${props => props.injectStyle}
`

Section.propTypes = {
    format: PropTypes.string,
    size: PropTypes.string,
    background: PropTypes.shape({
        color: PropTypes.string,
        image: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.shape({
                url: PropTypes.string.required,
                overlayColor: PropTypes.string.required
            })
        ])
    }),
    direction: PropTypes.oneOf(['column', 'row']),
    wrap: PropTypes.bool,
    align: PropTypes.shape({
        vertical: PropTypes.oneOf(['top', 'middle', 'bottom']),
        horizontal: PropTypes.oneOf(['left', 'center', 'right'])
    }),
    breakpoints: PropTypes.arrayOf(PropTypes.shape({
        condition: Object.string,
        css: PropTypes.object
    }))
}

Section.defaultProps = {
    align: { vertical: 'top', horizontal: 'left' }
}