
/**
 * index.js
 */

import React from 'react'
import Helmet from 'react-helmet'
import styled from 'styled-components'

import { Overlay } from 'modules/overlay'
import { Section } from 'modules/section'
import { Grid, GridItem } from 'modules/grid'

import HeroImage from 'assets/hero.jpg'
import HomePage1 from 'assets/homepage1.jpg'
import HomePage2 from 'assets/homepage2.jpg'
import HomePage3 from 'assets/homepage3.jpg'

const Hero = styled.div`
    position: absolute;
    height: 100%;
    width: 100%;
    background-image: url(${props => props.image });
    background-attachment: ${props => props.fixed ? 'fixed' : ''};
    background-size: cover;
    background-position: center center;
`

const Index = () => (

    <>

        <Helmet title='Home of the Warthog' />

        <Section
            id='hero-image' aria-label='hero-section'
            format='header' size='lg'
            direction='column' align={{ vertical: 'middle', horizontal: 'center' }}
            css={{ padding: '25px' }}
            breakpoints={[
                { condition: '(min-width: 600px)', css: { padding: '5%' } },
                { condition: '(min-width: 1200px)', css: { padding: '10%' } }
            ]}
        >

            <Hero image={HeroImage} fixed />

            <Overlay color={'rgba(0,0,0,.6)'} />

            <h1 style={{ color: 'white' }}>
                Home of the Warthog
            </h1>

        </Section>

        <Section
            aria-label='who-are-we'
            direction='row' align={{ vertical: 'middle', horizontal: 'left' }}
        >

            <Grid vertical='top'>
                <GridItem size={3}>
                    <Hero image={HomePage1} />
                </GridItem>
                <GridItem size={1} direction='column' horizontal='center' padding='50px'>
                    <h2>Who We Are</h2>
                    <p>
                        Founded in 2008, the Wheelmen are a Halo clan for teamwork-oriented players and fans of the Warthog. Read more about us here.
                    </p>
                </GridItem>
            </Grid>

        </Section>

        <Section
            aria-label='who-are-we'
            direction='row' align={{ vertical: 'middle', horizontal: 'right' }}
        >

            <Grid vertical='top'>
                <GridItem size={1} direction='column' horizontal='center' padding='50px'>
                    <h2>Why Play with Us?</h2>
                    <p>
                        You want to play videogames with polite teammates. The Wheelmen focus heavily on developing talent and teamwork, especially through our guides.
                    </p>
                </GridItem>
                <GridItem size={3}>
                    <Hero image={HomePage2} />
                </GridItem>
            </Grid>

        </Section>

        <Section
            aria-label='who-are-we'
            direction='row' align={{ vertical: 'middle', horizontal: 'left' }}
        >

            <Grid vertical='top'>
                <GridItem size={3}>
                    <Hero image={HomePage3} />
                </GridItem>
                <GridItem size={1} direction='column' horizontal='center' padding='50px'>
                    <h2>Join Now!</h2>
                    <p>
                        Whether you're interested in joining the clan or playing with some quality teammates, post an introduction here and get into our lobbies.
                    </p>
                </GridItem>
            </Grid>

        </Section>

    </>

)

export default Index