
import styled from 'styled-components'

export const Overlay = styled.div`

    position: absolute;
    height: 100%;
    width: 100%;

    background-color: ${props => props.color || ''};
    opacity: ${props => props.opacity || 1};

`