
import { css } from 'styled-components'
import { objectToCSS } from 'modules/styled/objectToCSS'

const generateBreakpoints = (breakpoints) => {
    let returnStr = ``

    if (breakpoints) {
        breakpoints.forEach((breakpoint) => {
            returnStr += `
                @media ${breakpoint.condition} {
                    ${objectToCSS(breakpoint.css)}
                }
            `
        })
    }

    return returnStr
}

export const breakpointMixin = css`
    ${props => props.breakpoints ? generateBreakpoints(props.breakpoints) : ''}
`